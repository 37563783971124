const init = function(){
  if(document.querySelectorAll("#sample_controller").length != 0){
    document.getElementById("sample_controller").onclick = playSample;
  }
}


const playSample = function(){
  var sel = document.getElementById("sample_controller")
  var player = document.getElementById("sample_player")

  player.oncanplay = null;

  if(sel.innerText == "⏹︎ stop playing" || sel.innerText == "Loading..."){
    // Stop the audio
    player.pause();
    sel.innerText = "⏵︎ play sample";
  } else {

    // Set the source file
    player.pause();

    // Set the link text
    sel.innerText = "⏹︎ stop playing";

    // Play the sample file (starts when file is ready)
    player.play();

    // Update text to stop when the playing starts
    player.oncanplay = function(){
      sel.innerText = "⏹︎ stop playing";
    };

    // Listen for the end state
    player.onended = function(){
      sel.innerText = "⏵︎ play sample";
    };

  }
};

module.exports = {
  init: init,
  playSample: playSample
}
