const YT = require("./YTplayer.js")
const BGplayer = require("./BGplayer.js");

const init = function(){
    YT.startYouTube();
    BGplayer.init();
}


// Check the DOM is ready and Start the app
if(document.readyState != "loading"){
  init();
} else {
  // Wait to start the app
  document.addEventListener("DOMContentLoaded", init);
}



module.exports = {
    YT: YT,
    BGplayer: BGplayer,
    init: init
}
