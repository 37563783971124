var players = {};
var videoTimer;

const startYouTube = function(){
  //Loads the YouTube API

  if(document.querySelectorAll(".YTplayer").length > 0){
    if(typeof(YT)=="undefined"){
      // Add Youtube API script
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      window.onYouTubeIframeAPIReady();
    }
  }
}


// gets page-specific videos information (in a separate page)
// NB; must be in global scope for iframe API to call-back
window.onYouTubeIframeAPIReady = function() {
  var playerElems = document.querySelectorAll(".YTplayer")

  for(elem of playerElems){
    var YTid = elem.getAttribute("YTid"),
      divID = elem.getAttribute("id"),
      YTstart = elem.getAttribute("YTstart"),
      YTend = elem.getAttribute("YTend");

      // Optional sizes
      var width = parseFloat(getComputedStyle(elem).width),
        height = parseFloat(getComputedStyle(elem).height);

      if(width == 0){width = "640"};
      if(height == 0){height = "360"};

    createPlayer(divID, YTid, YTstart, YTend, width, height);
  };
}


function createPlayer(ref, videoID, start, end, width, height) {
  if (typeof start === "undefined") start = 0;
  if (typeof end === "undefined") end = 10800;
  players[ref] = new YT.Player(ref, {
    height: "360",
    width: "640",
    videoId: videoID,
    playerVars: {
      start: start,
      end: end,
      "showinfo": 0,
      playsinline: 1,
    },
    events: {
      "onStateChange": onPlayerStateChange
    }
  });
}


// to stop other video players once a video player starts playing
function onPlayerStateChange(event) {
  if(event.data == 1) {
    var temp = event.target.getVideoUrl();
    for(var i in players){
      if(players[i].getVideoUrl() != temp){
        players[i].pauseVideo();
      }
    }
  }
}


function jumpToTimecode(ref, timeStart, timeEnd){
  function pauseVideo() {
    players[ref].pauseVideo();
  }

  function timeStampToSeconds(ts){
    comps = ts.split(":").reverse()
    seconds = 0
    for(var c in comps){
      seconds += (Math.pow(60,c)) * parseInt(comps[c])
    }

    return seconds
  }

  // Convert timestamps
  timeStart = timeStampToSeconds(timeStart)

  // as timeEnd is an optional argument
  if (typeof timeEnd === "undefined"){
    timeEnd = 0
  } else {
    timeEnd = timeStampToSeconds(timeEnd)
  };

  // first stops all other players
  for(var i in players){
    if(i!=ref){
      players[i].pauseVideo();
    }
  }

  // then jumps to the right place and starts playing
  players[ref].seekTo(timeStart);
  players[ref].playVideo();


  // if timeEnd was given, stops the video at the timeEnd timecode
  if (timeEnd != 0) {

    //+2 to account for starting and ending second
    var playDuration = ((timeEnd - timeStart)+2)*1000;
    window.clearTimeout(videoTimer);
    videoTimer = setTimeout(pauseVideo, playDuration);
  }
}

// Convenience reference
window.jmp = jumpToTimecode;

module.exports ={
  startYouTube: startYouTube,
  jmp: jmp,
  players: players
}
